import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.store('videoStatusPreview', {
    playing: {},
    muted: {},
    readmore: {},

    isPlaying(videoId) {
        return this.playing[videoId] || false;
    },

    setPlaying(videoId, status) {
        if (typeof this.playing[videoId] === 'undefined') {
            this.playing[videoId] = false;
        }
        this.playing[videoId] = status;
    },

    isMuted(videoId) {
        return this.muted[videoId] || false;
    },

    setMuted(videoId, status) {
        if (typeof this.muted[videoId] === 'undefined') {
            this.muted[videoId] = false;
        }
        this.muted[videoId] = status;
    },
    isReadmore(videoId) {
        return this.readmore[videoId] || false;
    },

    setReadmore(videoId, status) {
        this.readmore[videoId] = status;
    }

});



Alpine.data('swiperComponentPreview', () => ({
    videoSwiper: null,
    videoVisible: false,
    isSwiperReady: false,
    isFullscreen: false,

    init() {
        this.waitForSwiperReady();
    },
    exposePlayVideo() {
        window.playVideo = (videoId) => {
            this.playVideo(videoId);
        };
    },
    waitForSwiperReady() {
        const swiperElement = this.$refs.videoSwiper;

        if (!swiperElement) {
            console.error('Swiper elementet blev ikke fundet.');
            return;
        }

        const checkSwiperReady = setInterval(() => {
            if (swiperElement.swiper) {
                clearInterval(checkSwiperReady);
                this.videoSwiper = swiperElement.swiper;
                this.isSwiperReady = true;

                this.videoSwiper.on('slideChange', async () => {
                    const videos = this.$refs.videoSwiper.querySelectorAll('video');

                    for (const video of videos) {
                        try {
                            await video.pause();
                            Alpine.store('videoStatusPreview').setPlaying(video.getAttribute('data-video-id'), false);
                            video.currentTime = 0;
                        } catch (error) {
                            console.warn('Fejl ved pause af video:', error);
                        }
                    }

                    const activeSlide = this.videoSwiper.slides[this.videoSwiper.activeIndex];
                    if (activeSlide) {
                        const activeVideoId = activeSlide.getAttribute('data-video-id');
                        const activeVideo = this.$refs[`video${activeVideoId}`];
                        const activeSlug = activeSlide.getAttribute('data-slug');

                        if (activeVideo) {
                            Alpine.store('videoStatus').setPlaying(activeVideoId, false);
                            Alpine.store('videoStatus').setMuted(activeVideoId, activeVideo.muted);

                            try {
                                await activeVideo.play();
                                Alpine.store('videoStatus').setPlaying(activeVideoId, true);
                            } catch (error) {
                                console.warn('Fejl ved afspilning af video:', error);
                            }

                           

                           
                            const newUrl = `${window.location.origin}/reels/${activeSlug}`; // Konstruer den nye URL
                            window.history.pushState({ videoId: activeVideoId }, '', newUrl);
                        }
                    }
                });
            }
        }, 100);
    },
    closeFullscreen(videoId) {
        const selectedVideo = this.$refs[`video${videoId}`];
        if (selectedVideo) {
            selectedVideo.pause();
            Alpine.store('videoStatusPreview').setPlaying(videoId, false);
            Alpine.store('videoStatusPreview').setMuted(videoId, selectedVideo.muted);
            selectedVideo.currentTime = 0;
        }
    
        this.isFullscreen = false;
        this.videoVisible = false;
    
        const element = document.getElementById("orginal-reel");
        if (element) {
            const prevSlug = element.dataset.originalSlug;
            const rootUrl = window.location.origin + "/reels/" + prevSlug; 
            window.history.pushState({}, '', rootUrl); 
    
        } else {
            console.error("Element with ID 'orginal-reel' not found.");
        }
    },
    playVideo(videoId) {
        console.log(`Attempting to play video with ID: ${videoId}`);

        const checkReadyState = setInterval(() => {
            if (this.isSwiperReady && this.videoSwiper) {
                const slides = this.$refs.videoSwiper.querySelectorAll('.swiper-slide');
                let targetIndex = 0;

                // Find the correct slide based on the video ID
                slides.forEach((slide, index) => {
                    if (slide && slide.getAttribute('data-video-id') === videoId) {
                        targetIndex = index;
                    }
                });

                this.videoSwiper.slideTo(targetIndex); // Navigate to the correct slide
                this.videoVisible = true;
                this.isFullscreen = true;

                const selectedVideo = this.$refs[`video${videoId}`];

                if (selectedVideo) {
                    // Clear the interval once Swiper and video are ready
                    clearInterval(checkReadyState);

                    Alpine.store('videoStatusPreview').setPlaying(videoId, false);
                    Alpine.store('videoStatusPreview').setMuted(videoId, selectedVideo.muted);

                    // Wait for video to load
                    selectedVideo.addEventListener('loadeddata', () => {
                        selectedVideo.play();
                        Alpine.store('videoStatusPreview').setPlaying(videoId, true);
                        console.log(`Video ${videoId} is now playing.`);
                    });

                    // If video is already loaded, play immediately
                    if (selectedVideo.readyState >= 3) {
                        selectedVideo.play();
                        Alpine.store('videoStatusPreview').setPlaying(videoId, true);
                        console.log(`Video ${videoId} is playing immediately (already loaded).`);
                    }
                } else {
                    console.warn(`Video element with ID ${videoId} not found.`);
                }
            } else {
                console.log('Swiper instance or video not ready yet, retrying...');
            }
        }, 100); // Check every 300ms if Swiper and video are ready
    },
    togglePlay(videoId) {
        const video = this.$refs[`video${videoId}`];
        if (video) {
            // Initialisér status for videoen
            Alpine.store('videoStatusPreview').setPlaying(videoId, false);

            if (video.paused) {
                video.play().then(() => {
                    Alpine.store('videoStatusPreview').setPlaying(videoId, true);
                    console.log(`Video ${videoId} is now playing.`);
                }).catch(error => {
                    console.error(`Error playing video ${videoId}:`, error);
                });
            } else {
                video.pause();
                Alpine.store('videoStatusPreview').setPlaying(videoId, false);
                console.log(`Video ${videoId} is paused.`);
            }
        } else {
            console.error(`Video element with ID ${videoId} not found.`);
        }
    },
    isToggled(videoId) {
        const currentStatus = Alpine.store('videoStatusPreview').isReadmore(videoId);
        Alpine.store('videoStatusPreview').setReadmore(videoId, !currentStatus);
    },
    toggleMute(videoId) {
        const video = this.$refs[`video${videoId}`];
        if (video) {
            Alpine.store('videoStatusPreview').setMuted(videoId, video.muted);

            video.muted = !video.muted;
            Alpine.store('videoStatusPreview').setMuted(videoId, video.muted);
            console.log(`Video ${videoId} is now ${video.muted ? 'muted' : 'unmuted'}.`);
        } else {
            console.error(`Video element with ID ${videoId} not found.`);
        }
    },
    nextSlide() {
        if (this.isSwiperReady && this.videoSwiper) {
            const currentIndex = this.videoSwiper.activeIndex;
            const totalSlides = this.videoSwiper.slides.length;
            const nextIndex = (currentIndex + 1) % totalSlides; // Hvis loop er slået til, kan du håndtere dette med modulus
            this.videoSwiper.slideTo(nextIndex); // Skift direkte til næste slide
        } else {
            console.error('Swiper is not ready yet.');
        }
    },
    
    prevSlide() {
        if (this.isSwiperReady && this.videoSwiper) {
            const currentIndex = this.videoSwiper.activeIndex;
            const prevIndex = (currentIndex - 1 + this.videoSwiper.slides.length) % this.videoSwiper.slides.length;
            this.videoSwiper.slideTo(prevIndex); // Skift direkte til forrige slide
        } else {
            console.error('Swiper is not ready yet.');
        }
    }
}));
