import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('bottomNavOpacity', () => ({
    opacity: 0,
    lastScrollY: window.scrollY,
    showSearch: false, // Ny variabel til at styre visningen af søgeformularen

    init() {
        window.addEventListener('scroll', () => {
            this.opacity = window.scrollY > this.lastScrollY ? 0.7 : 1;
            if (window.scrollY < 200) {
                this.opacity = 0;
            } else if (window.scrollY > this.lastScrollY) {
                /*this.opacity = Math.max(this.opacity - 0.7, 0.75);*/
                this.opacity = Math.min(this.opacity + 0.7, 1);
            } else {
                /*this.opacity = Math.min(this.opacity + 0.7, 1);*/
                this.opacity = Math.min(this.opacity + 0.7, 1);
            }
            this.lastScrollY = window.scrollY;
        });
    },

    toggleSearch() { 
        this.showSearch = !this.showSearch;
        if(this.showSearch) {
            this.opacity = Math.min(this.opacity + 0.7, 1);
        }
    }
}));