import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.store('videoStatus', {
    playing: {},
    muted: {},
    readmore: {},

    isPlaying(videoId) {
        return this.playing[videoId] || false;
    },

    setPlaying(videoId, status) {
        if (typeof this.playing[videoId] === 'undefined') {
            this.playing[videoId] = false;
        }
        this.playing[videoId] = status;
    },

    isMuted(videoId) {
        return this.muted[videoId] || false;
    },

    setMuted(videoId, status) {
        if (typeof this.muted[videoId] === 'undefined') {
            this.muted[videoId] = false;
        }
        this.muted[videoId] = status;
    },
    isReadmore(videoId) {
        return this.readmore[videoId] || false;
    },

    setReadmore(videoId, status) {
        this.readmore[videoId] = status;
    }

});



Alpine.data('swiperComponent', () => ({
    videoSwiper: null,
    videoVisible: false,
    isSwiperReady: false,
    isFullscreen: false,

    init() {
        this.waitForSwiperReady();    
    },

    waitForSwiperReady() {
        const swiperElement = this.$refs.videoSwiper;

        if (!swiperElement) {
            console.error('Swiper elementet blev ikke fundet.');
            return;
        }

        const checkSwiperReady = setInterval(() => {
            if (swiperElement.swiper) {
                clearInterval(checkSwiperReady);
                this.videoSwiper = swiperElement.swiper;
                this.isSwiperReady = true;

                this.videoSwiper.on('slideChange', async () => {
                    const videos = this.$refs.videoSwiper.querySelectorAll('video');
                    
                    for (const video of videos) {
                        try {
                            await video.pause();
                            Alpine.store('videoStatus').setPlaying(video.getAttribute('data-video-id'), false);
                            video.currentTime = 0;
                        } catch (error) {
                            console.warn('Fejl ved pause af video:', error);
                        }
                    }

                    const activeSlide = this.videoSwiper.slides[this.videoSwiper.activeIndex];
                    if (activeSlide) {
                        const activeVideoId = activeSlide.getAttribute('data-video-id');
                        const activeVideo = this.$refs[`video${activeVideoId}`];
                        const activeSlug = activeSlide.getAttribute('data-slug');
                    
                        if (activeVideo) {
                            Alpine.store('videoStatus').setPlaying(activeVideoId, false);
                            Alpine.store('videoStatus').setMuted(activeVideoId, activeVideo.muted);
                    
                            try {
                                await activeVideo.play();
                                Alpine.store('videoStatus').setPlaying(activeVideoId, true);
                            } catch (error) {
                                console.warn('Fejl ved afspilning af video:', error);
                            }
                            const newUrl = `${window.location.origin}/reels/${activeSlug}`; // Konstruer den nye URL
                            window.history.pushState({ videoId: activeVideoId }, '', newUrl);
                        }
                    }
                });
            }
        }, 100);
    },
    closeFullscreen(videoId) {   
        const selectedVideo = this.$refs[`video${videoId}`];
        if (selectedVideo) {
            selectedVideo.pause();
            Alpine.store('videoStatus').setPlaying(videoId, false);
            Alpine.store('videoStatus').setMuted(videoId, selectedVideo.muted);
            selectedVideo.currentTime = 0;
        }
    
        this.isFullscreen = false;
        this.videoVisible = false;
    
        const rootUrl = window.location.origin; 
        window.history.pushState({}, '', rootUrl);
    },
    
    playVideo(videoId) {
        if (this.isSwiperReady && this.videoSwiper) {
            const slides = this.$refs.videoSwiper.querySelectorAll('.swiper-slide');
            let targetIndex = 0;

            slides.forEach((slide, index) => {
                if (slide && slide.getAttribute('data-video-id') === videoId) {
                    targetIndex = index;
                }
            });

            this.videoSwiper.slideTo(targetIndex);

            this.videoVisible = true;
            this.isFullscreen = true;

            setTimeout(() => {
                const selectedVideo = this.$refs[`video${videoId}`];
                if (selectedVideo) {
                    Alpine.store('videoStatus').setPlaying(videoId, false);
                    Alpine.store('videoStatus').setMuted(videoId, selectedVideo.muted);

                    selectedVideo.addEventListener('loadeddata', () => {
                        selectedVideo.play();
                        Alpine.store('videoStatus').setPlaying(videoId, true);
                    });

                    if (selectedVideo.readyState >= 3) {
                        selectedVideo.play();
                        Alpine.store('videoStatus').setPlaying(videoId, true);
                    }
                }
            }, 300);
            
        } else {
            console.error('Swiper instansen er ikke initialiseret korrekt.');
        }
    },

    togglePlay(videoId) {
        const video = this.$refs[`video${videoId}`];
        if (video) {
            Alpine.store('videoStatus').setPlaying(videoId, false);

            if (video.paused) {
                video.play().then(() => {
                    Alpine.store('videoStatus').setPlaying(videoId, true);
                    console.log(`Video ${videoId} is now playing.`);
                }).catch(error => {
                    console.error(`Error playing video ${videoId}:`, error);
                });
            } else {
                video.pause();
                Alpine.store('videoStatus').setPlaying(videoId, false);
                console.log(`Video ${videoId} is paused.`);
            }
        } else {
            console.error(`Video element with ID ${videoId} not found.`);
        }
    },
    isToggled(videoId) {
        const currentStatus = Alpine.store('videoStatus').isReadmore(videoId);
        Alpine.store('videoStatus').setReadmore(videoId, !currentStatus);
    },
    toggleMute(videoId) {
        const video = this.$refs[`video${videoId}`];
        if (video) {
            Alpine.store('videoStatus').setMuted(videoId, video.muted);

            video.muted = !video.muted;
            Alpine.store('videoStatus').setMuted(videoId, video.muted);
            console.log(`Video ${videoId} is now ${video.muted ? 'muted' : 'unmuted'}.`);
        } else {
            console.error(`Video element with ID ${videoId} not found.`);
        }
    },
    nextSlide() {
        if (this.isSwiperReady && this.videoSwiper) {
            const currentIndex = this.videoSwiper.activeIndex;
            const totalSlides = this.videoSwiper.slides.length;
            const nextIndex = (currentIndex + 1) % totalSlides; // Hvis loop er slået til, kan du håndtere dette med modulus
            this.videoSwiper.slideTo(nextIndex); // Skift direkte til næste slide
        } else {
            console.error('Swiper is not ready yet.');
        }
    },
    
    prevSlide() {
        if (this.isSwiperReady && this.videoSwiper) {
            const currentIndex = this.videoSwiper.activeIndex;
            const prevIndex = (currentIndex - 1 + this.videoSwiper.slides.length) % this.videoSwiper.slides.length;
            this.videoSwiper.slideTo(prevIndex); // Skift direkte til forrige slide
        } else {
            console.error('Swiper is not ready yet.');
        }
    }
}));
