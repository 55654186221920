import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";
Alpine.data('messages', () => ({
    currentIndex: 0,
    itemCount: 0, // Antallet af elementer i collection
    init() {
        this.itemCount = this.$refs.slider.children.length;
        this.hideAllButCurrent();
        this.rotateItems();
    },
    rotateItems() {
        setInterval(() => {
            this.currentIndex = (this.currentIndex + 1) % this.itemCount;
            this.hideAllButCurrent();
        }, 5000); // Skifter element hver 5. sekund
    },
    hideAllButCurrent() {
        Array.from(this.$refs.slider.children).forEach((item, index) => {
            item.style.display = index === this.currentIndex ? 'flex' : 'none';
        });
    }
}));