import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('topmenu', () => ({
  hide: false,
  start: 0,
  timer: null,
  shouldHide: false,
  init () {
    window.addEventListener('topNavCommand', this.topNavCommandHandler.bind(this));
    this.$watch('Alpine.store("burgerMenuShow")', (value) => {
      this.toggleBodyScroll(value);
    });
  },
  topNavCommandHandler (event) {
    this.hide = this.shouldHide = event.detail.hidden;
    this.broadcast();
  },
  scrollHandler (evt, triggeredByTimeout = false) {
    let scroll = window.scrollY;
    let burgerMenuVisible = Alpine.store('burgerMenuShow');
    if (!burgerMenuVisible) {
      if (scroll < 40 || scroll < (this.start - 200)) { // show the menu
        this.shouldHide = false;
      } else if (scroll >= this.start + 10) { // hide the menu
        this.shouldHide = true;
      }
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (!triggeredByTimeout) {
        this.timer = setTimeout(this.scrollHandler.bind(this, {}, true), 250);
      }
    }
    if (this.hide != this.shouldHide) {
      this.hide = this.shouldHide;
      this.broadcast();
    }
    this.start = scroll;
  },
  toggleBodyScroll(isBurgerMenuVisible) {
    if (isBurgerMenuVisible) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  },
  broadcast () {
    this.$el.dispatchEvent(new CustomEvent('navVisibilityChange', { detail: { hidden: this.hide }, bubbles: true }));
  }
}));
